import api from "../services/api";

// Получить схемы погрузки (с информацией) для отображения в результатах плана
export async function getPlanTransportGroupResultLoadingScheme(
  width: number,
  type: number,
  loadingSchemeId: any,
  parentId: any
) {
  return await api.get(
    "/PlanTransportGroupResultLoadingScheme/GetLoadingScheme",
    {
      params: {
        width,
        type,
        loadingSchemeId,
        IdParent: parentId,
      },
    }
  );
}
